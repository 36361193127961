<!--
 * @Description: 设备详情
 * @Author: ChenXueLin
 * @Date: 2021-09-03 09:06:54
 * @LastEditTime: 2022-05-06 17:28:22
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
    >
      <!-- 表格 start -->
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 头部状态 -->
          <detail-title-content
            :btnType="btnType"
            :btnText="`使用状态：${detailInfo.sevStatusName}`"
          ></detail-title-content>
          <!-- 基本信息 start -->
          <div class="baseInfo-box">
            <div class="edit-title">基本信息</div>
            <el-form label-width="140px" class="line-form">
              <el-row>
                <el-col :span="6">
                  <el-form-item label="设备编号：">
                    {{ detailInfo.equipCode }}
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="商品名称：">
                    {{ detailInfo.thirdClassName }}
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="商品分类：">
                    {{ detailInfo.firstClassName }}
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="商品开票名称：">
                    {{ detailInfo.secondClassName }}
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="商品类型：">
                    {{ detailInfo.virtualTypeName }}
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="固定/移动：">
                    {{ detailInfo.fixedOrMovedName }}
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <el-form label-width="140px" class="line-form">
              <el-row>
                <el-col :span="6">
                  <el-form-item label="所属人：">
                    {{ detailInfo.ownerName }}
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="所属人类型：">
                    {{ detailInfo.belongTypeName }}
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="持有人：">
                    {{ detailInfo.haveName }}
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="持有人类型：">
                    {{ detailInfo.haveTypeName }}
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <el-form label-width="140px" class="line-form">
              <el-row>
                <el-col :span="6">
                  <el-form-item label="安装场景：">
                    {{ detailInfo.sceneName }}
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="场景类型：">
                    {{ detailInfo.sceneTypeName }}
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="安装组合：">
                    {{ detailInfo.installGroupName }}
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="SIM卡号：">
                    {{ detailInfo.simNo }}
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="中心识别码：">
                    {{ detailInfo.commids }}
                  </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-form-item label="加密芯片号：">
                    {{ detailInfo.encryptionChipCode }}
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import detailTitleContent from "@/components/detailTitleContent";
import { getEquipDetail } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "equipDetail",
  components: {
    detailTitleContent
  },
  data() {
    return {
      searchForm: {},
      activeName: "one",
      detailInfo: {
        equipId: "", //设备id
        equipCode: "", //设备编号
        thirdClassName: "", //商品名称
        firstClassName: "", //商品分类
        secondClassName: "", //商品开票名称
        virtualTypeName: "", //商品类型
        fixedOrMovedName: "", //固定/移动
        belongTypeName: "", //所属人类型
        haveName: "", //持有人
        haveTypeName: "", //持有人类型
        sceneName: "", //安装场景
        sceneTypeName: "", //场景类型
        installGroup: {}, //安装组合
        sevStatus: "", // 使用状态
        sevStatusName: "", //使用状态名称
        installGroupName: "",
        simNo: "", //SIM卡号
        commids: "", //中心识别码
        encryptionChipCode: "" //加密芯片号
      }
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {
    btnType() {
      let btnType = "";
      switch (this.detailInfo.sevStatus) {
        case 1:
          btnType = "greenBtn";
          break;
        case 2:
          btnType = "yellowBtn";
          break;
        case 3:
          btnType = "blueBtn";
          break;
        case 4:
          btnType = "blueBtn";
          break;
        case 5:
          btnType = "yellowBtn";
          break;
      }
      return btnType;
    }
  },
  watch: {},
  created() {
    this.initForm();
  },
  activated() {
    this.handleRefreshUpdatePage();
  },
  methods: {
    initForm() {
      let hasParamsRoute = this.getHasParamsRoute();
      this.detailInfo.equipId = hasParamsRoute.params.equipId;
      if (this.detailInfo.equipId) {
        this.getEquipDetail();
      }
    },
    //获取设备详情
    async getEquipDetail() {
      try {
        this.loading = true;
        let res = await getEquipDetail({
          id: this.detailInfo.equipId
        });
        if (res.code !== "OK") {
          return;
        }
        let data = this.getFreezeResponse(res, {
          path: "data"
        });
        this.detailInfo = JSON.parse(JSON.stringify(data));
        if (this.detailInfo.installGroup) {
          this.detailInfo.installGroupName = this.detailInfo.installGroup.secondClassName;
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.baseInfo-box {
  width: 100%;
  .edit-title {
    width: 100%;
    margin-top: 10px;
  }
  .line-form {
    margin-top: 15px;
    border-bottom: 1px solid #f0f0f0;
  }
  /deep/.el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 15px;
  }
}
.table-wrapper {
  .repair-count {
    font-size: 14px;
    padding: 15px 15px 0px;
    box-sizing: border-box;
    & > :nth-child(1) {
      font-weight: bold;
      margin-right: 5px;
    }
  }
  .elTable {
    margin-top: 15px;
  }
}
</style>
